// [PROD] Manday 2025
export const sheetUrl = 'https://api.sheetbest.com/sheets/2e57edd6-fbf1-4256-8a9b-12427fd2eb34'
// [PROD] Day Off 2025
export const leaveSheetUrl = 'https://api.sheetbest.com/sheets/a95dda3a-d42a-4c46-a758-51d3b9673096'

// [PROD] White board Sheet
export const whiteBoardSheetUrl = 'https://sheet.best/api/sheets/bfc697e3-4986-4ee4-9e92-ddbb9200110f'

export const trackingUrl = 'https://tracking.ksta.co/'
export const secretPass = 'password1234'

export const middleware = 'https://tracking-notify.ksta.co/sheet'

// key must be 64 char
export const encryptionKey = 'mNGp6fxDgU4fZXMwtCNBGfLUnNlH7bIuNoOqVFIOZlm3vb4dePWQYGyqcks67Q4m'
